















































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { vxm } from '@/store'
import { mask } from 'vue-the-mask'

@Component({
  directives: {
    mask,
  },
})
export default class CustomerBonusGroupCreate extends Vue {
  $refs: Vue['$refs'] & {
    form: {
      validate: any
    }
  }

  pageMode = 'create'

  isSaving = false

  inputName = null
  inputBonusPercentage = null

  // i18n is not initialized here, so add rules at created function
  rules = {}

  created() {
    if (this.$route.params.id !== undefined) {
      this.getEditData()
      this.pageMode = 'edit'
    }

    this.rules = {
      name: [(v) => !!v || this.$t('c:validation:This field is required')],
      bonusPercentage: [
        (v) => !!v || this.$t('c:validation:This field is required'),
        (v) => !v || /^\d+(\.\d+)?$/.test(v) || this.$t('validation.positiveDecimalField'),
      ],
    }
  }

  getEditData() {
    this.$axios
      .get('/v3/customers/bonus_group/create/get_edit_data/' + this.$route.params.id)
      .then((response) => {
        this.inputName = response.data.data.customerBonusGroup.name
        this.inputBonusPercentage = response.data.data.customerBonusGroup.bonusPercentage
      })
      .catch((err) => {
        err.response.data.errors.forEach((v, i) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
        this.$router.push({
          name: 'customers/bonus_group/list',
        })
      })
  }

  save() {
    if (this.$refs.form.validate()) {
      this.isSaving = true

      const data = {
        id: this.$route.params.id,
        name: this.inputName,
        bonusPercentage: this.inputBonusPercentage,
      }
      this.$axios
        .post('/v3/customers/bonus_group/create/save', data)
        .then((response) => {
          vxm.alert.success({
            content: this.$t('c:common:Successfully saved') as string,
            title: this.$t('c:common:Success') as string,
          })
          this.$router.push({
            name: 'Customers/BonusGroup/List',
          })
        })
        .catch((err) => {
          err.response.data.errors.forEach((v, i) => {
            vxm.alert.error({
              content: v.message as string,
              title: this.$t('c:common:Error') as string,
            })
          })
        })
        .finally(() => {
          this.isSaving = false
        })
    }
  }
}
